import { render, staticRenderFns } from "./RealizedServices.vue?vue&type=template&id=63bb9f94&scoped=true&"
import script from "./RealizedServices.vue?vue&type=script&lang=js&"
export * from "./RealizedServices.vue?vue&type=script&lang=js&"
import style0 from "./RealizedServices.vue?vue&type=style&index=0&id=63bb9f94&lang=scss&scoped=true&"
import style1 from "./RealizedServices.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63bb9f94",
  null
  
)

export default component.exports